import { UserCredential } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import React from 'react';
import useQueryParams from '../hooks/useQueryParams';
import useRedirectResult from '../hooks/useRedirectResult';
import { useFirebase } from '../services/firebase';
import { getUsersInNeedOfMigration } from '../services/firebase/database';
import { LoginState } from '../services/firebase/FirebaseContext';
import { LOADING_STATE } from '../util/loading';
import LoginForm from './login/LoginForm';
import LoginScreen from './login/LoginScreen';
import PhoneLoginForm from './login/PhoneLoginForm';

interface LoginProps {}

function useLoginMethod(): 'phone' | 'email' {
  const queryParams = useQueryParams();
  const methodParam = queryParams.get('method');
  if (methodParam === 'phone') {
    return 'phone';
  }
  return 'email';
}

function useUsersInNeedOfMigration() {
  const [users, setUsers] = React.useState<string[]>([]);
  const { app } = useFirebase();
  React.useEffect(() => {
    const database = getDatabase(app);
    getUsersInNeedOfMigration(database).then(setUsers).catch(console.error);
  }, []);

  return users;
}

type RedirectCallback = (result?: UserCredential | null) => Promise<void>;
interface ILoginContext {
  redirectResult: {
    result?: UserCredential | null;
    loadingState: LOADING_STATE;
  };
  addRedirectListener: (callback: RedirectCallback) => void;
}

export const LoginContext = React.createContext<ILoginContext>(null as any);

const Login: React.FC<LoginProps> = (props) => {
  const method = useLoginMethod();
  const usersInNeedOfMigration = useUsersInNeedOfMigration();

  const redirectListeners = React.useRef<RedirectCallback[]>([]);
  const addRedirectListener = React.useCallback(
    (callback: RedirectCallback) => {
      redirectListeners.current = redirectListeners.current.concat(callback);
    },
    []
  );
  const redirectCallback = React.useCallback(
    (result: UserCredential | null) => {
      const promises = redirectListeners.current.map((callback) =>
        callback(result)
      );
      return Promise.all(promises) as any;
    },
    []
  );
  const redirectResult = useRedirectResult(redirectCallback);
  const { loginState } = useFirebase();
  const isLoading =
    loginState === LoginState.LOADING ||
    redirectResult.loadingState === LOADING_STATE.LOADING;

  return (
    <LoginContext.Provider value={{ redirectResult, addRedirectListener }}>
      <LoginScreen title="Login" isLoading={isLoading}>
        {method === 'email' ? (
          <LoginForm usersInNeedOfMigration={usersInNeedOfMigration} />
        ) : (
          <PhoneLoginForm usersInNeedOfMigration={usersInNeedOfMigration} />
        )}
      </LoginScreen>
    </LoginContext.Provider>
  );
};

export default Login;
