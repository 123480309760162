import React from 'react';
import styled from 'styled-components';
import Logo from '../../components/common/Logo';
import MarketingPage from '../../components/page/MarketingPage';
import HomeLink from '../home/HomeLink';
import SubmitButton from '../home/SubmitButton';
import aboutIcon from '../../images/v2/home-icons/SVG/Pocket Pathologist-Icon_Microscope-transparent.svg';
import faqIcon from '../../images/v2/home-icons/SVG/Pocket Pathologist-Icon_FAQs-transparent.svg';
import pricingIcon from '../../images/v2/home-icons/SVG/Pocket Pathologist-Icon_Pricing-transparent.svg';
import educationIcon from '../../images/v2/home-icons/SVG/Pocket Pathologist-Icon_Learning-transparent.svg';
import BREAKPOINTS from '../../constants/breakpoints';
import TrackView from '../../components/analytics/TrackView';
import { VIEW_EVENTS } from '../../services/buildfire/analytics/events';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import NavMenu from '../../components/common/NavMenu';
import Toolbar from '../../components/common/Toolbar';
import Platform from '../../components/common/Platform';
import AlertBanner from '../../components/common/AlertBanner';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
`;

const ToolbarContainer = styled(IonHeader)`
  // padding-top: constant(ion-safe-area-top); // for ios 13+
  // padding-top: var(--ion-safe-area-top); // for previous ios versions
`;

const LogoSubheaderContainer = styled.div``;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  img {
    max-width: 400px;
  }

  @media (max-width: ${BREAKPOINTS.small}px) {
    img {
      // max-width: 263px;
      max-width: 100%;
    }
  }
`;

const Header = styled.h3<{ $fontSize: string }>`
  text-align: left;
  ${(props) => `font-size: ${props.$fontSize};`}
  color: var(--ion-color-primary);
  font-weight: 400;
  text-transform: uppercase;
`;

const Subheader = styled.p<{ $fontSize: string }>`
  text-align: center;
  ${(props) => `font-size: ${props.$fontSize};`}
  color: var(--accent-color4);
  line-height: 1.2em;
  margin-top: 12px;
  margin-bottom: 24px;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const NavigationListContainer = styled.div`
  padding: 0 8px;
  position: relative;

  > a {
    margin-bottom: 32px;
  }
`;

const ButtonContainer = styled.div`
  max-width: 480px;
`;

const Home = () => {
  return (
    <>
      <IonPage>
        <ToolbarContainer>
          <Toolbar showBackButton={false} showMenu />
          <AlertBanner />
        </ToolbarContainer>
        <TrackView name={VIEW_EVENTS.HOME}>
          <IonContent>
            <MarketingPage>
              <Container>
                <LogoSubheaderContainer>
                  <Platform.Mobile>
                    <LogoContainer>
                      <Logo />
                    </LogoContainer>
                  </Platform.Mobile>

                  {/* <Platform.Desktop>
                  <Header $fontSize="32px">
                    Your Personal, Digital Pathologist
                  </Header>
                  <Subheader $fontSize="24px">
                    Providing expert in-house cytology support <br />
                    through smartphone image capture
                  </Subheader>
                </Platform.Desktop>
                <Platform.Mobile>
                  <Header $fontSize="16px">
                    Your Personal, Digital Pathologist
                  </Header>
                  <Subheader $fontSize="16px">
                    Providing expert in-house cytology support <br />
                    through smartphone image capture
                  </Subheader>
                </Platform.Mobile> */}

                  <Platform.Mobile>
                    <Subheader $fontSize="20px">
                      Telecytology Consults
                    </Subheader>
                  </Platform.Mobile>
                </LogoSubheaderContainer>

                <ButtonContainer>
                  <NavigationListContainer>
                    <SubmitButton />
                    <HomeLink to="/about" img={aboutIcon}>
                      About Us
                    </HomeLink>
                    <HomeLink to="/pricing" img={pricingIcon}>
                      Pricing
                    </HomeLink>
                    <HomeLink to="/faq" img={faqIcon}>
                      Tips
                    </HomeLink>
                    <HomeLink to="/education" img={educationIcon}>
                      Education Station
                    </HomeLink>
                  </NavigationListContainer>
                </ButtonContainer>
              </Container>
            </MarketingPage>
          </IonContent>
        </TrackView>
      </IonPage>
    </>
  );
};

export default Home;
