import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonModal,
  IonToolbar,
} from '@ionic/react';
import classNames from 'classnames';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import Form from '../../components/common/Form';
import { uppercaseFirstLetter } from '../../util/string';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useQueryParams from '../../hooks/useQueryParams';
import { useFirebase } from '../../services/firebase';
import Typography from '../../components/common/Typography';
import useIonicModalProps from '../../hooks/useIonicModalProps';
import { modEmail } from '../../services/firebase/database';
import {
  ButtonRow,
  CenteredRow,
  HiddenSubmitButton,
  Link,
} from './common/styledComponents';
import GoogleSignInButton from './common/GoogleSignInButton';
import AppleSignInButton from './common/AppleSignInButton';
import OutlookSignInButton from './common/OutlookSignInButton';
import { isPlatform } from '@ionic/core';
import { redirectAfterAuth } from '../../util/auth';
import { AuthCredential, EmailAuthProvider, User } from 'firebase/auth';

interface LoginFormProps {
  usersInNeedOfMigration?: string[];
  user?: User;
  reauth?: boolean;
  onReauth?: (userCredential: AuthCredential) => Promise<void>;
}

const ModalContent = styled.div`
  padding: 32px;
  text-align: center;
`;
const SSOContainer = styled.div`
  margin-top: 64px;
  display: flex;
  align-items: center;
  p {
    margin-right: 8px;
  }
  button {
    margin: 0 8px;
  }
`;
const LoginButton = styled(IonButton)`
  width: 100%;
`;

const ChangePasswordPrompt: React.FC<{
  email: string;
  onClose: () => void;
}> = ({ email, onClose }) => {
  return (
    <ModalContent>
      <Typography fontSize="24px" lineHeight="1.4em" margin="16px 0">
        Due to a system upgrade, all existing users are required to reset their
        passwords.
      </Typography>

      <Typography fontSize="16px" lineHeight="1.4em" margin="32px 0">
        If you have not already reset your password, please click the button
        below to receive an email to change your password.
      </Typography>

      <Typography
        fontSize="16px"
        lineHeight="1.4em"
        margin="32px 0"
        fontWeight="bold"
      >
        If you would like to keep your existing password, you may make your new
        password the same as your old password when you fill out the password
        reset form.
      </Typography>

      <CenteredRow>
        <IonButton routerLink={`/reset-password?email=${email}`}>
          Reset Password
        </IonButton>
      </CenteredRow>
      <CenteredRow>
        <IonButton onClick={onClose} fill="clear">
          Return to Login
        </IonButton>
      </CenteredRow>
    </ModalContent>
  );
};

const SIGN_IN_BUTTONS = [
  !isPlatform('ios') && <GoogleSignInButton key="google" />,
  // AppleSignInButton,
  // OutlookSignInButton
];

const LoginForm: React.FC<LoginFormProps> = ({
  usersInNeedOfMigration = [],
  user,
  reauth,
  onReauth = () => Promise.resolve(),
}) => {
  const history = useHistory();
  const params = useQueryParams();
  const { signIn } = useFirebase();

  const searchParams = useQueryParams();
  const modalProps = useIonicModalProps();
  const [hasBeenPrompted, setHasBeenPrompted] = React.useState(
    searchParams.get('reset') === 'true'
  );
  const [showResetPasswordModal, setShowResetPasswordModal] =
    React.useState(false);

  const oauthButtons = SIGN_IN_BUTTONS.filter(Boolean);

  return (
    <Formik
      initialValues={{
        email: user?.email || '',
        password: '',
      }}
      onSubmit={async (values, helpers) => {
        const moddedEmail = modEmail(values.email);
        if (
          !hasBeenPrompted &&
          usersInNeedOfMigration.find((email) => moddedEmail === email)
        ) {
          setShowResetPasswordModal(true);
          setHasBeenPrompted(true);
          return;
        }

        try {
          if (reauth) {
            const credential = EmailAuthProvider.credential(
              values.email,
              values.password
            );
            await onReauth(credential);
            return;
          }

          await signIn(values.email, values.password);
          redirectAfterAuth(params, history);
        } catch (e) {
          console.error(e);
          helpers.setFieldError('password', 'Invalid email or password');
        }
      }}
      validationSchema={Yup.object({
        email: Yup.string().email().required(),
        password: Yup.string().required(),
      })}
      validateOnMount
    >
      {({
        values,
        isSubmitting,
        isValid,
        setFieldValue,
        setFieldTouched,
        errors,
        touched,
        submitForm,
      }) => (
        <>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              submitForm();
            }}
          >
            {!reauth && (
              <IonInput
                className={classNames({
                  ['ion-invalid']: errors.email,
                  ['ion-touched']: touched.email,
                })}
                label="Email"
                labelPlacement="floating"
                type="email"
                name="email"
                required
                autofocus
                inputmode="email"
                value={values.email}
                onIonInput={(e) => setFieldValue('email', e.detail.value)}
                onIonBlur={() => setFieldTouched('email', true)}
                errorText={uppercaseFirstLetter(errors.email as string)}
              />
            )}
            <IonInput
              className={classNames({
                ['ion-invalid']: errors.password,
                ['ion-touched']: touched.password,
              })}
              label="Password"
              labelPlacement="floating"
              color="secondary"
              type="password"
              name="password"
              required
              clearOnEdit={false}
              value={values.password}
              onIonInput={(e) => setFieldValue('password', e.detail.value)}
              onIonBlur={() => setFieldTouched('password', true)}
              errorText={uppercaseFirstLetter(errors.password as string)}
            />

            <ButtonRow>
              <LoginButton
                type="submit"
                disabled={!isValid || isSubmitting}
                expand="block"
              >
                Login
              </LoginButton>
            </ButtonRow>

            {/* Need this for Ionic hack... so the form submits on enter */}
            <HiddenSubmitButton type="submit" />
          </Form>

          <CenteredRow>
            <Link routerLink="?method=phone">Login with phone number</Link>
          </CenteredRow>

          {!reauth && (
            <CenteredRow>
              <Link routerLink="/reset-password">Forgot password?</Link>
            </CenteredRow>
          )}

          {!reauth && (
            <CenteredRow>
              <Link routerLink="register">Register</Link>
            </CenteredRow>
          )}

          {oauthButtons.length > 0 && (
            <CenteredRow>
              <SSOContainer>
                <Typography fontWeight="500">Sign in with:</Typography>
                {oauthButtons}
              </SSOContainer>
            </CenteredRow>
          )}

          <IonModal
            isOpen={showResetPasswordModal}
            onDidDismiss={() => setShowResetPasswordModal(false)}
            {...modalProps}
          >
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="end">
                  <IonButton onClick={() => setShowResetPasswordModal(false)}>
                    Close
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <ChangePasswordPrompt
                email={values.email}
                onClose={() => setShowResetPasswordModal(false)}
              />
            </IonContent>
          </IonModal>
        </>
      )}
    </Formik>
  );
};

export default LoginForm;
